.fill-white{
    fill: white!important;
}

.white-text{
    color: white;
}

.noselect{
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
